import React, { Component, Fragment } from "react";
import yellow_star from "../../images/yellow-star.png";
import grey_star from "../../images/grey-star.png";
import CardHeading from "../utils/CardHeading";
import PropTypes from "prop-types";
import { DEFAULT_IMG_URL } from "../../constants/constants.js";

// In scroller pass in the two props heading and the data

const scroller_dump = [{}, {}];

class ScrollerSS extends Component {
  render() {
    return (
      <Fragment>
        <p className="scroller-heading">{this.props.heading}</p>
        <div className="scroller">
          <div className="card-container" style={{ width: "max-content" }}>
            {scroller_dump.map(({}, index) => {
              return (
                <div className="card">
                  <div className="img-ss"></div>
                  <div className="dum-1"></div>
                  <div className="dum-2"></div>
                  {/* <div className="learn-btn-ss"></div> */}
                </div>
              );
            })}
          </div>
        </div>
      </Fragment>
    );
  }
}

export default ScrollerSS;
