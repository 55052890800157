import React, { Component } from "react";
import Footer from "../footers/Footer";
import join_grp from "../../images/join-grp.svg";
import JoinGroupModal from "../modals/JoinGroupModal";
import JoinGroupForm from "../forms/JoinGroupForm.js";
import yellow_star from "../../images/yellow-star.png";
import grey_star from "../../images/grey-star.png";
import CardHeading from "../utils/CardHeading";
import FeaturedGroupCarousel from "../carousels/FeaturedGroupCarousel";
import TrendingGroupCarousel from "../carousels/TrendingGroupCarousel";
import GroupScroller from "../scrollers/GroupScroller";
import { PAGE_HEADINGS } from "../../constants/constants";
import { setPageHeading } from "../../actions/utilAction";
import { fetchExploreData } from "../../actions/exploreAction.js";
import { connect } from "react-redux";
import {
  toggleJoinGroupModal,
  getGroupInfo,
  raiseCodeInvalidError,
} from "../../actions/joinGroupAction.js";
import queryString from "query-string";

class JoinGroup extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.fetchExploreData();
    this.props.setPageHeading(PAGE_HEADINGS.JOIN_GROUP);
  }

  onGroupClicked = (gid, gcode) => {
    if (this.props.joined_gids[gid]) {
      this.redirectToGrpDetailPage(gid);
    } else {
      // this.props.redirectToGroupPage(gcode);
      this.props.getGroupInfo(gcode);
    }
  };

  redirectToGrpDetailPage = (gid) => {
    this.props.history.push({
      pathname: "/dashboard/group-detail",
      search: queryString.stringify(
        Object.assign({}, queryString.parse(this.props.location.search), {
          gid,
        })
      ),
    });
  };

  render() {
    if (this.props.is_gcode_valid) {
      this.props.toggleJoinGroupModal(true);
    }
    return (
      <div className="dashboard-content-wrapper">
        <div className="container-fluid dashboard-content custom-padding">
          <div className="content-header-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <p className="content-header">Join Group</p>
          </div>
          {/* <div className="row tab-row"> */}
          {/* <div className="content-header-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <p className="content-header">Join Group</p>
                    </div> */}
          {/* </div> */}
          {/* <div className="row tab-row"> */}
          <div className=" c-grp-container" id="grp_container_id">
            <div className="row">
              <div className="c-grp-form-container">
                <JoinGroupForm />
              </div>
              <div className="c-grp-img-container">
                <div className="gc-info-container">
                  <div className="gc-info-wrapper">
                    <div className="gc-info">
                      <p className="gc-heading">Group Codes</p>
                      <p className="gc-content">
                        Learning in Imarticus happens in Learning Groups.
                        <br /> Each Learning Group is identified with a unique
                        6-digit Group Code. Join a Learning Group by entering
                        its Group Code.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="ex-hr"></hr>
          <div className="explore-container">
            {/* Hide feature and trending groups */}
            {/* <p className="group-msg">Don’t have a Group Code? Check some of our groups.</p> */}
            {/* <hr className="group-hr"></hr> */}

            {/* Hide feature and trending groups */}
            {/*
                        <FeaturedGroupCarousel ref_id={"grp_container_id"} />
                        <div className="jg-extra-spacing"></div>
                        <TrendingGroupCarousel ref_id={"grp_container_id"} />
                        */}

            {/* </div> */}
          </div>
        </div>
        {/* <p className="scroller-heading">Free Groups</p> */}

        {/* Hide feature and trending groups */}
        {/*
                <GroupScroller heading={'Featured Groups'} data={this.props.ft_grp} />
                <GroupScroller heading={'Trending Groups'} data={this.props.tr_grp} />
                */}

        {/* <Footer /> */}
        <JoinGroupModal />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  tr_crs: state.explore.tr_crs,
  fr_crs: state.explore.fr_crs,
  ft_grp: state.explore.ft_grp,
  tr_grp: state.explore.tr_grp,
  toggle_j_grp_modal: state.j_grp.toggle_j_grp_modal,
  is_gcode_valid: state.j_grp.is_gcode_valid,
});

const mapDispatchToProps = {
  fetchExploreData,
  toggleJoinGroupModal,
  setPageHeading,
  getGroupInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(JoinGroup);
