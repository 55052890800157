import React, { useState, useEffect } from "react";
import Axios from "axios";
import axios from "axios";
import { withRouter } from "react-router";
import { CONFIGS } from "../../constants/constants";
import { utils } from "../../utility/utils";
import { useDispatch, connect } from "react-redux";
import Cookies from "universal-cookie";
import { Row, Col } from "reactstrap";
import { getProfileInfo } from "../../actions/profileAction";
const cookies = new Cookies();

const TroubleshootNotifications = (props) => {
  const dispatch = useDispatch();
  const [test1, setTest1] = useState(false);
  const [test2, setTest2] = useState(false);
  const [test3, setTest3] = useState(false);
  let status = false;
  let profile = props.profile;

  const checkInternetConnection = () => {
    return navigator.onLine;
  };

  const runTest1 = async () => {
    const isConnected = checkInternetConnection();
    setTest1(isConnected);
    return isConnected;
  };

  const runTest2 = async () => {
    await axios
      .post(`${CONFIGS.API_API_ENDPOINT}notifications/checkStatus`, {
        aid: utils.getAid(),
        client: "W",
        endpointId: cookies.get("endpointId", {
          domain: CONFIGS.DOMAIN,
          path: "/",
        }),
        tokn: utils.getAt(),
      })
      .then((res) => {
        console.log(res.data.data);
        status = res.data.data.ifEnabled && res.data.data.ifActive;
        setTest2(status);
      })
      .catch((err) => {
        console.log(err);
      });
    console.log({ status });
    return status;
  };

  const runTest3 = async () => {
    await axios
      .post(`${CONFIGS.API_API_ENDPOINT}notifications/testNotification`, {
        aid: utils.getAid(),
        tokn: utils.getAt(),
      })
      .then((res) => {
        setTest3(res.data.success);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRunTests = async () => {
    setTest1(false);
    setTest2(false);
    setTest3(false);
    const result1 = await runTest1();
    console.log({ result1 });
    if (result1) {
      const result2 = await runTest2();
      console.log({ result2 });
      if (result2) {
        await runTest3();
      } else {
        console.log("\n\n ====== In updateEndpoints ======");
        axios
          .post(CONFIGS.API_API_ENDPOINT + "notifications/up_ep", {
            aid: cookies.get("aid"),
            client: "w",
            deviceToken: props.token,
            inapp: true,
            tokn: utils.getAt(),
            rt: cookies.get("rt"),
          })
          .then((res) => {
            console.log("\n\n DeviceToken registered successfully! \n\n");
            // dispatch({type: "UPDATE_ENDPOINT", token})
            console.log("\n\nDispatching an event!\n\n\n");
            console.log(
              "\nres.data.data.ids.push_endpointId\n",
              res.data.data.ids.push_endpointId
            );
            dispatch({
              type: "TOKEN_SENT",
              ifTokenSent: true,
              endpointId: res.data.data.ids.push_endpointId,
            });
            cookies.set("ifTokenSent", true);
            cookies.set("endpointId", res.data.data.ids.push_endpointId);
          })
          .catch((err) => {
            console.log("We are in error :: ", err, "\n\n\n");
            // utils.handleErrors(err,dispatch);
          });
      }
    }
  };

  const changeEmailSubscription = () => {
    console.log(" --- Changed the subscription --- ");
    Axios.post(`${CONFIGS.API_ENDPOINT}notifications/sbscrb_email`, {
      aid: utils.getAid(),
      tokn: utils.getAt(),
      ifSubscribed: !profile.ifSubscribed,
    })
      .then((res) => {
        console.log("Response successful!");
        dispatch({
          type: "CHANGE_SUBSCRIPTION",
          ifSubscribed: !profile.ifSubscribed,
        });
        return;
      })
      .catch((err) => {
        console.log("Error: ", err);
        return;
      });
  };

  return (
    <div
      className="group-db-content-wrapper"
      style={{ fontFamily: "Source Sans Pro" }}
    >
      <div className="container-fluid dashboard-content">
        <div className="profile-setting-container-row">
          <div
            className="profile-setting-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
            style={{ padding: "20px 20px" }}
          >
            <div style={{ paddingLeft: "124px", paddingRight: "124px" }}>
              <div
                style={{
                  textAlign: "center",
                  marginTop: "51px",
                  fontSize: "24px",
                  fontWeight: "600",
                  lineHeight: "32px",
                  color: "#3c4852",
                }}
              >
                Troubleshoot Notifications
              </div>
              <div
                style={{
                  marginTop: "31px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: "18px",
                    lineHeight: "22px",
                    color: "#3c4852",
                    display: "block",
                    marginTop: "35px",
                  }}
                >
                  Tests
                  <ul
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "18px",
                      color: "#3c4852",
                      marginTop: "32px",
                    }}
                  >
                    <li>Checking your internet connection.</li>
                    <li style={{ marginTop: "16px" }}>
                      Checking if your notifications are active.
                    </li>
                    <li style={{ marginTop: "16px" }}>
                      Sending a test notification
                    </li>
                  </ul>
                </div>
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: "18px",
                    lineHeight: "22px",
                    color: "#3c4852",
                    display: "block",
                    marginTop: "35px",
                  }}
                >
                  <p style={{ cursor: "pointer" }} onClick={handleRunTests}>
                    Run Tests
                  </p>
                  <div
                    style={{
                      fontWeight: "400",
                      fontSize: "14px",
                      lineHeight: "18px",
                      color: "#3c4852",
                      marginTop: "32px",
                      textAlign: "center",
                    }}
                  >
                    <div>{test1 ? "✅" : "❌"}</div>
                    <div style={{ marginTop: "16px" }}>
                      {test2 ? "✅" : "❌"}
                    </div>
                    <div style={{ marginTop: "16px" }}>
                      {test3 ? "✅" : "❌"}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  marginTop: "24px",
                  fontWeight: "400",
                  fontSize: "18px",
                  lineHeight: "18px",
                  color: "#3c4852",
                }}
              >
                Still Having Trouble
              </div>
              <div
                style={{
                  marginTop: "16px",
                  fontWeight: "400",
                  fontSize: "14px",
                  lineHeight: "18px",
                  color: "#3c4852",
                }}
              >
                Try clearing the cookies and cache and log out of your account
                and log in again. It should fix most of your problem. If you
                keep facing the problem, please contact us.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid dashboard-content">
        <div className=" profile-setting-container-row">
          <div className="profile-setting-container-foot col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <Row className="adques-footer">
              <Col md={7} className="jobs-table-header-2">
                <input
                  checked={profile.ifSubscribed}
                  // onChange={changeEmailSubscription}
                  className="form-check-input"
                  // style={{ float: "right", position: "inherit" }}
                  type="checkbox"
                  name="subscribeToEmail"
                />
                <p style={{ fontSize: "14px" }}>
                  <b>Subscribe to email notifications by Imarticus? </b>
                  {console.log("\n\n\n ifSubscribed :::: ", {
                    pifSub: profile.ifSubscribed,
                  })}
                  (Currently {profile.ifSubscribed ? "On" : "Off"})
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  user_j_groups: state.j_grp.groups,
  courses: state.course.courses,
  course_groups: state.course.groups,
  combo_courses: state.course.combo_courses,
  resume_course: state.course.resume_course,
  live_lectures: state.course.live_lectures,
  is_data_loading: state.utils.is_data_loading,
  is_loading: state.utils.is_loading,
  current_tab: state.utils.current_tab,
  is_not_found: state.utils.is_not_found,
  is_err: state.utils.is_err,
  is_pf_chngd: state.utils.is_pf_chngd,
  show_sidebar_mb: state.utils.show_sidebar_mb,
  is_back: state.utils.is_back,
  show_loading_modal: state.utils.is_loading_md,
  email_waiting: state.primaryEmail.waiting_verification,
  selected_course: state.course.selected_course,
  brand: state.course.brand,
  individualBrand: state.course.individualBrand,
  contact: state.profile.contact,
  profileName: state.profile.p_name,
  email: state.primaryEmail.secondary_emails[0],
  profile: state.profile,
  course: state.course.courses[0],
  token: state.primaryEmail.token,
});

const mapDispatchToProps = { getProfileInfo };
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TroubleshootNotifications)
);

// export default TroubleshootNotifications;
