import { connect } from "react-redux";
import { setSearch } from "../../actions/utilAction";
import React, { Component, Fragment } from "react";

class SearchBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
    };
  }

  showMenu = (event) => {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener("click", this.closeMenu);
    });
  };

  closeMenu = (event) => {
    if (!this.dropdownMenu.contains(event.target)) {
      this.setState({ showMenu: false }, () => {
        document.removeEventListener("click", this.closeMenu);
      });
    }
  };

  render() {
    return (
      <Fragment>
        <div className="search-container">
          <input
            className="search-inp-web"
            placeholder="Search for groups, courses"
            onChange={this.showMenu}
          />
          <i
            className="fa fa-times search-close"
            onClick={() => this.props.setSearch(false)}
          ></i>
        </div>
        {this.state.showMenu ? (
          <div
            className="search-menu"
            ref={(element) => {
              this.dropdownMenu = element;
            }}
          >
            <ul>
              <li>+Add Profile</li>
              <li>Edit Profile</li>
              {/* <li>Send Feedback</li> */}
              <li>Contact Us</li>
              <li>Logout</li>
            </ul>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  is_search: state.utils.is_search,
});

const mapDispatchToProps = {
  setSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
