import { connect } from "react-redux";
import React from "react";
import { withRouter } from "react-router";
import { Modal } from "reactstrap";
import group_default from "../../images/groups-01-01.svg";
import { redirectToMailForNoGroupForCourse } from "../../actions/courseAction";
import { KEY_CODES } from "../../constants/constants";

class NoGroupForCourseModal extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keydownHandler);
  }

  cancel = () => {
    this.props.toggleNoGroupForCourseModal();
  };

  keydownHandler = (e) => {
    if (e.keyCode == KEY_CODES.ESC_KEY && this.props.is_open) {
      this.props.toggleNoGroupForCourseModal();
    }
  };

  toMail = () => {
    this.props.toggleNoGroupForCourseModal();
    this.props.redirectToMailForNoGroupForCourse(
      this.props.crs_name,
      this.props.p_name
    );
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.is_open}
          className="lg-modal-container"
          centered={true}
        >
          <div className="lg-modal">
            <div className="top-section">
              <div className="heading">Error</div>
              <p className="ngfc-msg">
                Please refer your email for more details about this course.
                Still have doubts?
              </p>
            </div>
            <div className="bottom-section">
              <div className="btn-y" onClick={this.toMail}>
                Yes
              </div>
              <div
                className="btn-n"
                onClick={this.props.toggleNoGroupForCourseModal}
              >
                No
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  p_name: state.profile.p_name,
});

const mapDispatchToProps = {
  redirectToMailForNoGroupForCourse,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NoGroupForCourseModal)
);
