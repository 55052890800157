import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { FRONTEND_ERRORS as f_err } from "../../constants/errors";
import err_chat from "../../images/err-chat.svg";
import GroupCodeTooltip from "../tooltips/GroupCodeTooltip.js";
import JoinGroupModal from "../modals/JoinGroupModal";
import {
  toggleJoinGroupModal,
  getGroupInfo,
  raiseCodeInvalidError,
} from "../../actions/joinGroupAction.js";

class JoinGroupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      grp_code: "",
      form_incomplete: false,
      err_code: "",
      err_msg: "",
    };
  }
  joinGroup = (e) => {
    if (e) {
      var g_code = this.state.grp_code.trim();
      e.preventDefault();
    }
    if (this.validateForm()) {
      this.props.getGroupInfo(g_code);
    }
  };

  validateForm = () => {
    if (this.state.grp_code.trim().length != 6) {
      this.props.raiseCodeInvalidError(true, "F0003", f_err["F0003"]);
      return false;
    }
    return true;
  };

  setGroupCode = (e) => {
    // alert(e.target.value.length);
    var val = null;
    var isnum = true;
    if (e && e.target && e.target.value && e.target.value.length) {
      val = e.target.value;
      isnum = /^\d+$/.test(val);
      if (!isnum) {
        return;
      }
    }
    if (e.target.value.length > 6) {
      return;
    }
    if (!isnum) {
      return;
    }
    this.setState({
      grp_code: e.target.value,
    });
    if (this.state.grp_code.trim().length > 0) {
      this.props.raiseCodeInvalidError(false, "", "");
    }
  };

  handleKeyPress = (e) => {
    if (e.key === "Enter") {
      this.joinGroup();
    } else if (e.which < 48 || e.which > 57) {
      e.preventDefault();
    } else if (this.state.grp_code.length >= 6) {
      e.preventDefault();
    }
  };

  render() {
    return (
      <Fragment>
        <form className="join-grp-form">
          {/* <label className="jg-form-label">Enter Group Code &nbsp;<GroupCodeTooltip /></label><br /> */}
          <label className="jg-form-label">Enter Group Code</label>
          <br />
          <input
            onChange={this.setGroupCode}
            value={this.state.grp_code}
            onKeyPress={this.handleKeyPress}
            className={
              this.props.form_incomplete ? "jg-inp-er err-inp" : "jg-inp "
            }
          ></input>
          <p
            className={
              "jg-err-web " + (this.props.form_incomplete ? "err-msg" : "hide")
            }
          >
            <img src={err_chat} className="err-icon"></img>
            {this.props.err_msg}
          </p>
          <input
            onClick={this.joinGroup}
            type="submit"
            className="jg-btn"
            value="Join Group"
          ></input>
          <p
            className={
              "jg-err-mb " + (this.props.form_incomplete ? "err-msg" : "hide")
            }
          >
            <img src={err_chat} className="err-icon"></img>
            {this.props.err_msg}
          </p>
        </form>
        {/* <JoinGroupModal /> */}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  toggle_j_grp_modal: state.j_grp.toggle_j_grp_modal,
  is_gcode_valid: state.j_grp.is_gcode_valid,
  form_incomplete: state.j_grp.form_incomplete,
  err_code: state.j_grp.err_code,
  err_msg: state.j_grp.err_msg,
});

const mapDispatchToProps = {
  toggleJoinGroupModal,
  getGroupInfo,
  raiseCodeInvalidError,
};

export default connect(mapStateToProps, mapDispatchToProps)(JoinGroupForm);
