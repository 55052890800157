import React, { Component, Fragment } from "react";
import ErrorHeader from "../headers/ErrorHeader";
import NetworkErrorContent from "../homecontents/NetworkErrorContent";

class NetworkError extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_sidebar: false,
      is_search: false,
    };
  }

  render() {
    return (
      <Fragment>
        <ErrorHeader />
        <NetworkErrorContent />
      </Fragment>
    );
  }
}

export default NetworkError;
