import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import options from "../../images/three_dots.svg";
import { connect } from "react-redux";
import { withRouter } from "react-router";

class GroupDetailDropdown extends Component {
  constructor() {
    super();

    this.state = {
      showMenu: false,
      switch_profile: false,
    };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }

  showMenu(event) {
    event.preventDefault();

    this.setState({ showMenu: true }, () => {
      document.addEventListener("click", this.closeMenu);
    });
  }

  closeMenu(event) {
    if (this.dropdownMenu && !this.dropdownMenu.contains(event.target)) {
      this.setState({ showMenu: false }, () => {
        document.removeEventListener("click", this.closeMenu);
      });
    }
  }
  toggleSwitchProfile = () => {
    this.setState({
      switch_profile: !this.state.switch_profile,
    });
  };
  openLeaveGroupModal = () => {
    this.setState({ showMenu: false }, () => {
      document.removeEventListener("click", this.closeMenu);
    });
    this.props.toggleLeaveGroupModal();
  };

  render() {
    return (
      <Fragment>
        <button className="grp-options-btn" onClick={this.showMenu}>
          <img className="options-img" src={options} />
        </button>

        {this.state.showMenu ? (
          <div
            className="grp-dd-menu"
            ref={(element) => {
              this.dropdownMenu = element;
            }}
          >
            <ul className="grp-dd-list">
              <li
                className={!this.props.is_admin ? "grp-dd-item" : "hide"}
                onClick={this.openLeaveGroupModal}
              >
                Leave Group
              </li>
              <Link
                to={"/dashboard/gedit?gid=" + this.props.gid}
                className={this.props.is_admin ? "grp-dd-link" : "hide"}
              >
                <li className={this.props.is_admin ? "grp-dd-item" : "hide"}>
                  Edit Group
                </li>
              </Link>
            </ul>
          </div>
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  is_admin: state.j_grp.is_admin,
  gid: state.j_grp.gd_id,
});

const mapDispatchToProps = {};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GroupDetailDropdown)
);
