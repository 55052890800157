import React, { Component, Fragment } from "react";

// Basic Structure for Web ==>> [ [{}], [{},{}], [{},{}] ] , [ [{}], [{},{}],[{},{}] ] ,[ [{}], [{},{}],[{},{}] ], [ [{}], [{}] ] ]

// Basic Structure for Tablet ==>>  [ [{}], [{},{}] ] , [ [{}], [{},{}] ] ,[ [{}], [{},{}] ], [ [{}], [{}] ] ]

export default class GroupCarouselSS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      width_to_scroll: "",
    };
  }

  componentDidMount() {}

  showImg = (e) => {
    e.target.style.display = "block";
  };

  render() {
    return (
      <Fragment>
        <div className="carousel-header">
          <div className="carousel-heading">{this.props.heading}</div>
        </div>
        <div className="gc-ss-container">
          <div className="row g-card-row">
            <div className="col-6 col-lg-3 col-md-4 g-card-col">
              <div className="card">
                <div className="img-section"></div>
                <div className="bottom-section">
                  <div className="dum-1"></div>
                  <div className="dum-2"></div>
                </div>
              </div>
            </div>

            <div className="col-6 col-lg-3 col-md-4 g-card-col">
              <div className="card">
                <div className="img-section"></div>
                <div className="bottom-section">
                  <div className="dum-1"></div>
                  <div className="dum-2"></div>
                </div>
              </div>
            </div>

            <div className="col-6 col-lg-3 col-md-4 g-card-col">
              <div className="card">
                <div className="img-section"></div>
                <div className="bottom-section">
                  <div className="dum-1"></div>
                  <div className="dum-2"></div>
                  {/* <div className="dum-btn"> */}
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
