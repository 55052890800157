import axios from "axios";
import cookie from "react-cookies";
import { ROUTES } from "../constants/routes";
import { KEYS, RAZORPAY_STATUS, PLUGIN_CATEGORY } from "../constants/constants";
import {
  CONFIGS,
  TABS,
  LOADING_MODAL_MSG,
  AWS_BUCKET_URLS,
  POPOVER_CONTENT,
  POPOVER_HEADINGS,
  POPOVER_TYPE,
  POPOVER_HIDETIME,
  URLS,
  ROLES,
} from "../constants/constants";
import {
  BACKEND_ERRORS,
  BACKEND_ERROR_CODES,
  FRONTEND_ERRORS,
  ERROR_MESSAGES,
  ERROR_BLOCK,
} from "../constants/errors";
import { utils, handleErrors } from "../utility/utils";
import { base64StringtoFile } from "../utility/utils";
// import { setHomeTab, setCourseTab, setGroupTab} from '../actions/utilAction';
import { renewAccessToken } from "./utilAction";
import XMLParser from "../lib/xml-parser";

export const setSelectedPluginData = (plugin, packages) => (dispatch) => {
  if (!packages || !packages[plugin._id]) {
    return;
  }
  var action = {};
  action.type = "SET_PLUGIN_DETAILS";
  action.pl_packages = packages[plugin._id];
  action.pl = plugin;
  dispatch(action);
};

export const fetchRazorPayDetails =
  (
    plugin,
    pkg,
    gid,
    py_mode,
    updated_price,
    cpn_code,
    all_same_category_plugins
  ) =>
  (dispatch) => {
    var plugins = all_same_category_plugins;
    var data = {};
    if (cpn_code) {
      data.cpn_code = cpn_code;
    }
    data.famt =
      updated_price >= 0 ? updated_price.toString() + ".00" : pkg.package_price;
    data.g_id = gid;
    data.p_id = utils.getPid();
    data.p_mode = py_mode;
    data.pkg_id = pkg._id;
    data.pl_id = plugin._id;
    data.tamt = pkg.package_price;
    var configs = {};
    var headers = {};
    headers["x-access-token"] = utils.getAt();
    configs.headers = headers;
    axios
      .post(ROUTES.INITIALIZE_TRAN, data, configs)
      .then((res) => {
        res = res.data.data;
        if (!res) {
          throw new Error();
        }
        var rp_options = {
          key: KEYS.RAZORPAY_KEY,
          amount: res.params.TXN_AMOUNT, // 2000 paise = INR 20
          name: res.params.NAME,
          description: "#" + res.params.ORDER_ID,
          image: "https://www.eckovation.com/homed/images/logo.png",
          handler: function (response) {
            captureResponse(
              response,
              res.params.ORDER_ID,
              plugin._id,
              plugins,
              dispatch
            );
          },
          prefill: {
            contact: res.params.MOBILE_NO,
            email: res.params.EMAIL,
          },
          notes: {
            order_id: res.params.ORDER_ID,
          },
          theme: {
            color: "#064c7c",
          },
        };
        let rzp = new window.Razorpay(rp_options);
        rzp.open();
      })
      .catch((err) => {
        if (
          err.response &&
          err.response.data &&
          err.response.data.code == BACKEND_ERROR_CODES.PAYMENT_TRNX_SKIP
        ) {
          var pl_catg = null;
          var pl_id = plugin._id;
          utils.showPopover(
            POPOVER_HEADINGS.SUCCESS,
            POPOVER_CONTENT.PLUGIN_PAYMENT_SUCCESS,
            POPOVER_TYPE.SUCCESS,
            dispatch
          );
          if (plugins && plugins[0]) {
            if (plugins[0].catg == PLUGIN_CATEGORY.VIDEO) {
              pl_catg = PLUGIN_CATEGORY.VIDEO;
            } else if (plugins[0].catg == PLUGIN_CATEGORY.QUIZ) {
              pl_catg = PLUGIN_CATEGORY.QUIZ;
            }
          }

          var updated_plugins = [];
          for (var i = 0; i < plugins.length; i++) {
            var temp = {};
            for (var k in plugins[i]) {
              temp[k] = plugins[i][k];
            }
            if (plugins[i]._id == pl_id) {
              temp.is_sub = true;
            }
            updated_plugins.push(temp);
          }
          var action = {};
          if (pl_catg == PLUGIN_CATEGORY.VIDEO) {
            action.type = "UPDATE_PLUGIN_VIDEO";
            action.plugins = updated_plugins;
            dispatch(action);
          } else if (pl_catg == PLUGIN_CATEGORY.QUIZ) {
            action.type = "UPDATE_PLUGIN_QUIZ";
            action.plugins = updated_plugins;
            dispatch(action);
          }
          action = {};
          action.type = "SET_RAZORPAY_RES";
          action.rp_status = RAZORPAY_STATUS.SUCCESS;
          dispatch(action);
        } else {
          utils.handleErrors(err, dispatch, ERROR_BLOCK.INITIALIZE_TRAN);
        }
      });
  };

const captureResponse = function (res, trxn_id, pl_id, plugins, dispatch) {
  var data = {};
  var configs = {};
  configs.headers = {};
  data.rzp_id = res.razorpay_payment_id;
  configs.headers["x-access-token"] = utils.getAt();
  data.trxn_id = trxn_id;

  axios
    .post(ROUTES.RAZORPAY_CAPTURE, data, configs)
    .then((cap_res) => {
      var pl_catg = null;
      utils.showPopover(
        POPOVER_HEADINGS.SUCCESS,
        POPOVER_CONTENT.PLUGIN_PAYMENT_SUCCESS,
        POPOVER_TYPE.SUCCESS,
        dispatch
      );
      if (plugins && plugins[0]) {
        if (plugins[0].catg == PLUGIN_CATEGORY.VIDEO) {
          pl_catg = PLUGIN_CATEGORY.VIDEO;
        } else if (plugins[0].catg == PLUGIN_CATEGORY.QUIZ) {
          pl_catg = PLUGIN_CATEGORY.QUIZ;
        }
      }

      var updated_plugins = [];
      for (var i = 0; i < plugins.length; i++) {
        var temp = {};
        for (var k in plugins[i]) {
          temp[k] = plugins[i][k];
        }
        if (plugins[i]._id == pl_id) {
          temp.is_sub = true;
        }
        updated_plugins.push(temp);
      }
      var action = {};
      if (pl_catg == PLUGIN_CATEGORY.VIDEO) {
        action.type = "UPDATE_PLUGIN_VIDEO";
        action.plugins = updated_plugins;
        dispatch(action);
      } else if (pl_catg == PLUGIN_CATEGORY.QUIZ) {
        action.type = "UPDATE_PLUGIN_QUIZ";
        action.plugins = updated_plugins;
        dispatch(action);
      }
      action = {};
      action.type = "SET_RAZORPAY_RES";
      action.rp_status = RAZORPAY_STATUS.SUCCESS;
      dispatch(action);
    })
    .catch((err) => {
      var action = {};
      action.type = "SET_RAZORPAY_RES";
      action.rp_status = RAZORPAY_STATUS.FAILURE;
      dispatch(action);
      utils.handleErrors(err, dispatch, ERROR_BLOCK.RAZORPAY_TRAN);
    });
};

export const applyCouponCodeBackend = (pkg, cpn_code, gid) => (dispatch) => {
  var data = {};
  var configs = {};
  configs.headers = {};
  configs.headers["x-access-token"] = utils.getAt();
  data.amt = pkg.package_price;
  data.cpn_code = cpn_code;
  data.g_id = gid;
  data.p_id = utils.getPid();
  data.pkg_id = pkg._id;
  data.pl_id = pkg.plugin_id;
  axios
    .post(ROUTES.APPLY_COUPON, data, configs)
    .then((ap_res) => {
      ap_res = ap_res.data.data;
      var action = {};
      action.type = "COUPON_APPLIED";
      action.updated_price = parseInt(ap_res.famt);
      action.cpn_code = cpn_code;
      dispatch(action);
    })
    .catch((err) => {
      utils.handleErrors(err, dispatch, ERROR_BLOCK.APPLY_COUPON);
    });
};

export const resetCoupon = () => (dispatch) => {
  var action = {};
  action.type = "RESET_COUPON";
  dispatch(action);
};

export const resetRazorpayResponse = () => (dispatch) => {
  var action = {};
  action.type = "RESET_RAZORPAY_RES";
  dispatch(action);
};
