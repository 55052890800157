import { connect } from "react-redux";
import React, { Component, useState } from "react";
import axios from "axios";
import { withRouter } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import Header from "../headers/Header";
import CourseHeader from "../headers/CourseHeader";
import LeftSidebar from "../sidebars/LeftSidebar";
import CourseSidebar from "../sidebars/CourseSidebar";
import ErrorModal from "../modals/ErrorModal";
import { TABS, CONFIGS } from "../../constants/constants";
import {
  setCurrentTab,
  resetProfileChangedCounter,
} from "../../actions/utilAction";
import { getProfileInfo } from "../../actions/profileAction";
import { fetchMyCourses } from "../../actions/courseAction";
import { dispatchIndividualBrand } from "../../actions/brandAction";
import ContentContainer from "../homecontents/ContentContainer";
import SidebarContainer from "../sidebars/SidebarContainer";
import {
  setSearch,
  showSidebarMB,
  resetBackBtn,
} from "../../actions/utilAction";
import NotFound from "../errors/NotFound";
import NetworkError from "../errors/NetworkError";
import ErrorPopover from "../utils/Popover";
import PrimaryEmailModal from "../modals/PrimaryEmailModal";
import VerifyEmailModal from "../modals/VerifyEmailModal";
import {
  changeEmailVerifyLink,
  isPrimaryEmailVerified,
  isMobileVerified,
  getAllNotifications,
  updateEndpoints,
} from "../../actions/primaryEmailActions";
import announcementIcon from "../../images/speakerIcon.svg";
import { ROUTES } from "../../constants/routes";
import {
  Button,
  Input,
  Modal,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import Spinner from "../utils/Spinner";
import { utils } from "../../utility/utils";
import MobileModal from "../modals/MobileModal";
import VerifyMobileModal from "../modals/VerifyMobileModal";
import "./Dashboard.css";
import Cookies from "universal-cookie";
import { logoutUser } from "../../actions/accountDropdownAction";
let cookies = new Cookies();

// const { InAppMessaging } = Notifications;
class Dashboard extends Component {
  constructor(props) {
    super(props);
    // toDo()

    if (!cookies.get("isLoggedIn")) {
      logoutUser();
      window.location.href = "/login";
    }
  }

  componentDidUpdate(prev_props) {
    if (this.props.location !== prev_props.location) {
      this.onRouteChanged();
    }
    if (this.props.is_pf_chngd) {
      this.props.history.push({
        pathname: CONFIGS.DASHBOARD_URL,
      });
      this.props.resetProfileChangedCounter();
    }
    if (
      this.props.courses != prev_props.courses ||
      this.props.user_j_groups != prev_props.user_j_groups
    ) {
      this.props.setCurrentTab(this.getCurrentTab());
    }
    if (
      this.props.is_back &&
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname != "/dashboard/combo"
    ) {
      this.props.resetBackBtn();
    }
    let ifTokenSent = cookies.get("ifTokenSent");
    console.log(
      "This is the value of ifTokenSent ::: ",
      ifTokenSent,
      " and token :: ",
      this.props.token
    );
    if (
      !ifTokenSent &&
      // !cookies.get("ifTokenSent") &&
      this.props.token
    ) {
      console.log(
        "INUPDATEMOUNT !this.props.ifTokenSent && this.props.token : ",
        !this.props.ifTokenSent && this.props.token,
        !this.props.ifTokenSent && this.props.token
      );
      this.props.updateEndpoints(this.props.token);
    }
  }
  async componentDidMount() {
    await this.props.isPrimaryEmailVerified();
    await this.props.isMobileVerified();
    await this.props.getAllNotifications();
    // await InAppMessaging.identifyUser('AjayDhiman', {
    //   address: "dqg5inwpOAmHkVNHL40RtY:APA91bFXCbQSGyJAtERP7Lxo7KG20tJ_OSKp4JCm553fXeWb8Ah-mZ29nVLJA0yaEu9uS6A4S2aw-6dftP5KIShZ8hR-1fZC8iLX_GRRsEA2gGPOcOYIvi7OpOyvTFTzYKrNG8Qr8Px9",
    //   optOut: "NONE"
    // });
    if (
      this.props.location.search !== "" &&
      this.props.location.pathname == "/dashboard/verify_email"
    ) {
      this.props.changeEmailVerifyLink(this.props.location.search);
    } else {
      this.props.getProfileInfo(this.props.dispatchIndividualBrand);
    }
    this.props.setCurrentTab(this.getCurrentTab());
    let ifTokenSent = cookies.get("ifTokenSent");
    console.log(
      "This is the value of ifTokenSent ::: ",
      ifTokenSent,
      " and token :: ",
      this.props.token
    );
    if (
      !ifTokenSent &&
      // !cookies.get("ifTokenSent") &&
      this.props.token
    ) {
      console.log(
        "INCOMPONENTDIDMOUNT !this.props.ifTokenSent && this.props.token : ",
        !this.props.ifTokenSent && this.props.token,
        !this.props.ifTokenSent && this.props.token
      );
      this.props.updateEndpoints(this.props.token);
    }
    window.FreshworksWidget?.("hide", "launcher");
  }
  onRouteChanged() {
    var ct = this.getCurrentTab();
    this.props.setCurrentTab(ct);
    if (this.props.show_sidebar_mb) {
      setTimeout(
        function () {
          this.props.showSidebarMB(false);
        }.bind(this),
        10
      );
    }
  }

  getCurrentTab = () => {
    var current_tab;
    var pathname = this.props.location.pathname;
    switch (pathname) {
      case "/dashboard":
      case "/dashboard/": {
        if (this.props.courses && this.props.courses.length > 0) {
          current_tab = TABS.MY_COURSES;
        } else if (
          (!this.props.courses || this.props.courses.length == 0) &&
          this.props.user_j_groups &&
          this.props.user_j_groups.length > 0
        ) {
          current_tab = TABS.MY_GROUPS;
        } else {
          current_tab = TABS.HOME;
        }
        break;
      }
      case "/dashboard/explore":
      case "/dashboard/explore/":
        current_tab = TABS.EXPLORE;
        break;
      case "/dashboard/home":
      case "/dashboard/home/":
        current_tab = TABS.HOME;
        break;
      case "/dashboard/combo":
      case "/dashboard/combo/":
      case "/dashboard/course":
      case "/dashboard/course/":
        current_tab = TABS.MY_COURSES;
        break;
      case "/dashboard/group":
      case "/dashboard/group/":
        current_tab = TABS.MY_GROUPS;
        break;
      case "/dashboard/jgroup":
      case "/dashboard/jgroup/":
        current_tab = TABS.JOIN_GROUP;
        break;
      case "/dashboard/cgroup":
      case "/dashboard/cgroup/":
        current_tab = TABS.CREATE_GROUP;
        break;
      case "/dashboard/edit":
      case "/dashboard/edit/":
        current_tab = TABS.PROFILE_EDIT;
        break;
      case "/dashboard/editpr":
      case "/dashboard/editpr/":
        current_tab = TABS.PROFILE_NAME_EDIT;
        break;
      case "/dashboard/pwd_edit":
      case "/dashboard/pwd_edit/":
        current_tab = TABS.PASSWORD_EDIT;
        break;
      case "/dashboard/nsdcDetails":
      case "/dashboard/nsdcDetails/":
        current_tab = TABS.NSDC_DETAILS;
        break;
      case "/placement-module/basic_details":
      case "/placement-module/basic_details/":
        current_tab = TABS.BASIC_DETAILS;
        break;
      case "/placement-module/education_details":
      case "/placement-module/education_details/":
        current_tab = TABS.EDUCATION_DETAILS;
        break;
      case "/placement-module/professional_details":
      case "/placement-module/professional_details/":
        current_tab = TABS.PROFESSIONAL_DETAILS;
        break;
      case "/placement-module/resume":
      case "/placement-module/resume/":
        current_tab = TABS.RESUME;
        break;
      case "/placement-module/basic-details":
      case "/placement-module/basic-details/":
        current_tab = TABS.BASIC_DETAILS;
        break;
      case "/placement-module/education-details":
      case "/placement-module/education-details/":
        current_tab = TABS.EDUCATION_DETAILS;
        break;
      case "/placement-module/professional-details":
      case "/placement-module/professional-details/":
        current_tab = TABS.PROFESSIONAL_DETAILS;
        break;
      case "/placement-module/resume-details":
      case "/placement-module/resume-details/":
        current_tab = TABS.RESUME;
        break;
      case "/placement-module/profile_preview":
      case "/placement-module/profile_preview/":
        current_tab = TABS.PROFILE_PREVIEW;
        break;
      case "/dashboard/updt_mail":
      case "/dashboard/updt_mail/":
        current_tab = TABS.EMAIL_CHANGE;
        break;
      case "/dashboard/gedit":
      case "/dashboard/gedit/":
        current_tab = TABS.GROUP_EDIT;
        break;
      case "/dashboard/profile":
      case "/dashboard/profile/":
        current_tab = TABS.ADD_PROFILE;
        break;
      case "/dashboard/gdesc":
      case "/dashboard/gdesc/":
        current_tab = TABS.GROUP_DESC;
        break;

      case "/view/":
        current_tab = TABS.COURSE;
        break;
      case "/dashboard/notifications":
        current_tab = TABS.NOTIFICATIONS_ABC;
        break;
      case "/dashboard/manageDevices":
        current_tab = TABS.MANAGE_DEVICES;
        break;
      case "/dashboard/troubleshootNotifications":
        current_tab = TABS.TROUBLESHOOT_NOTIFICATIONS;
        break;
      case "/batch/":
        current_tab = TABS.COURSE_PAGE;
        break;
      case "/gradebook/":
        current_tab = TABS.GRADEBOOK;
        break;
      case "/certificate/":
        current_tab = TABS.CERTIFICATE;
        break;
      case "/certificate/":
        current_tab = TABS.CERTIFICATE_VIEW;
        break;
      case "/attendance/":
        current_tab = TABS.ATTENDANCE;
      case "/calendar/":
        current_tab = TABS.CALENDAR;
      default:
        current_tab = 0;
    }
    return current_tab;
  };

  render() {
    // console.log("\n\n", { notificationsInDashboard: this.props.notification });
    let inAppTitle, inAppBody;
    // if(this.props.notification && this.props.notification.title){
    //   console.log("InAppNotification Recieved - title ::: ", this.props.notification && this.props.notification.title)
    //   console.log("InAppNotification Recieved - body ::: ", this.props.notification && this.props.notification.body)
    //   // const img = '/to-do-notifications/img/icon-128.png';
    //   const title = this.props.notification.title
    //   // const notification = new Notification(title, { body: this.props.notification.body});
    // }
    if (!this.props.is_not_found && !this.props.is_err) {
      return (
        <div>
          <div className="dashboard-main-wrapper">
            <ErrorPopover />
            <div
              className={"app-loader " + (this.props.is_loading ? "" : "hide")}
            >
              <h1>LOADING...</h1>
            </div>
            {this.props.location.pathname == "/course" ? (
              <CourseHeader />
            ) : (
              <Header />
            )}

            <SidebarContainer />

            <ContentContainer />
            <ErrorModal />
            {/*{ this.props.show_loading_modal ?  
              <Spinner/> :''
            }*/}
            {/*{ this.props.email_waiting ?  
              <Spinner/> :''
            }*/}
            <PrimaryEmailModal />
            <VerifyEmailModal />
            <MobileModal />
            <VerifyMobileModal />
          </div>
          {console.log(
            "This is the inApp Notifications ::::: ",
            this.props.inAppNotification
          )}
          {this.props.inAppNotification &&
            this.props.inAppNotification.title && (
              <ToastForInApp
                title={this.props.inAppNotification.title}
                body={this.props.inAppNotification.body}
                img={this.props.inAppNotification.img}
                cid={this.props.inAppNotification.cid}
                campId={this.props.inAppNotification.campId}
                keyObj={this.props.inAppNotification.keyObj}
              />
            )}
        </div>
      );
    } else if (this.props.is_not_found) {
      return <NotFound />;
    } else {
      return <NetworkError />;
    }
  }
}

const mapStateToProps = (state) => ({
  is_loading: state.utils.is_loading,
  current_tab: state.utils.current_tab,
  profiles: state.profile.profiles,
  default_pid: state.profile.default_pid,
  courses: state.course.courses,
  is_not_found: state.utils.is_not_found,
  user_j_groups: state.j_grp.groups,
  combo_courses: state.course.combo_courses,
  resume_course: state.course.resume_course,
  live_lectures: state.course.live_lectures,
  brand: state.brand?.brand || state.course.brand,
  is_err: state.utils.is_err,
  is_pf_chngd: state.utils.is_pf_chngd,
  show_sidebar_mb: state.utils.show_sidebar_mb,
  is_back: state.utils.is_back,
  show_loading_modal: state.utils.is_loading_md,
  email_waiting: state.primaryEmail.waiting_verification,
  notifications: state.primaryEmail.notifications,
  ifTokenSent: state.primaryEmail.ifTokenSent,
  token: state.primaryEmail.token,
  inAppNotification: state.primaryEmail.in_app_notification,
});

const mapDispatchToProps = {
  changeEmailVerifyLink,
  setCurrentTab,
  getProfileInfo,
  fetchMyCourses,
  showSidebarMB,
  resetProfileChangedCounter,
  resetBackBtn,
  isPrimaryEmailVerified,
  isMobileVerified,
  getAllNotifications,
  updateEndpoints,
  dispatchIndividualBrand,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Dashboard)
);

const ToastForInApp = ({ title, body, img, cid, campId, keyObj }) => {
  const [ifDisplay, setIfDisplay] = useState(true);
  let styles = {
    thisIsMain: {
      background: "#000000ad",
      width: "100%",
      height: "100%",
      position: "fixed",
      top: "0",
      left: "0",
      zIndex: "999",
      ...(ifDisplay && {
        opacity: "1",
        transition: "width 0.5s, height 0.5s, opacity 0.5s 0.5s",
      }),
      ...(ifDisplay === false && {
        opacity: "0",
        display: "none",
        transition:
          "width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s, display 0.5s",
      }),
    },
    inAppNotification: {
      position: "relative",
      width: "495px",
      minHeight: "360px",
      borderRadius: "3%",
      background: "#fff",
      zIndex: "9999",
      border: "1px solid rgba(0,0,0,.1)",
      boxShadow: "0 0.25rem 0.75rem rgb(0 0 0 / 10%)",
      height: "auto",
      left: "calc(40% - 50px)",
      top: "30%",
      // position: "fixed",
      // right: "20px",
      // bottom: "20px",
      // top: "30%" || "calc(50vh - (/* height */200px / 2))",
      // left: "38%" || "calc(50vw - (/* width */140px / 2))",
      ...(ifDisplay && {
        opacity: "1",
        height: "auto",
        transition: "width 0.5s, height 0.5s, opacity 0.5s 0.5s",
      }),
      ...(ifDisplay === false && {
        opacity: "0",
        display: "none",
        transition:
          "width 0.5s 0.5s, height 0.5s 0.5s, opacity 0.5s, display 0.5s",
      }),
    },
    notificationHeader: {
      padding: "10px",
      display: "flex",
      borderBottom: "1px solid rgba(0,0,0,.05)",
    },
    notificationBody: {
      padding: "10px",
    },
  };
  const callTheSeenAPI = (aid, cid, campId) => {
    console.log(" ----- Call the Seen API ----- ");
    axios
      .post(`${CONFIGS.API_API_ENDPOINT}notifications/markNotificationAsSeen`, {
        aid,
        cid,
        campId,
        tokn: utils.getAt(),
      })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };
  return (
    <div className="thisIsMain" style={styles.thisIsMain}>
      <div style={styles.inAppNotification}>
        <button
          type="button"
          className="ml-2 mb-1 close"
          style={{
            position: "absolute",
            zIndex: "9999",
            right: "6px",
            color: "white",
          }}
          onClick={() => {
            callTheSeenAPI(utils.getAid(), cid, campId);
            setIfDisplay(false);
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>

        {keyObj ? (
          <Link
            target="_blank"
            to={{ pathname: keyObj }}
            style={{ cursor: "pointer" }}
            title={"Click here!"}
          >
            <div
              className="preview_colorfirst"
              style={{
                maxWidth: "496px",
                marginLeft: "-1px",
                marginTop: "-1px",
              }}
            >
              <img
                src={announcementIcon}
                alt="NewImage"
                style={{ position: "absolute", top: "0px", left: "0px" }}
              />
              <img
                src={img}
                style={{
                  width: "455px",
                  height: "230px",
                  borderRadius: "5%",
                  padding: "6px",
                }}
              />
            </div>
            <div style={{ marginTop: "248px", padding: "10px 20px" }}>
              <h3
                style={{ height: "40px", textTransform: "inherit" }}
                className="previewcolor_heading"
              >
                {title}
              </h3>
              <p
                style={{ wordWrap: "break-word", textTransform: "inherit" }}
                className="previewcolor_subheading"
              >
                {body}
              </p>
            </div>
          </Link>
        ) : (
          <>
            <div
              className="preview_colorfirst"
              style={{
                maxWidth: "496px",
                marginLeft: "-1px",
                marginTop: "-1px",
              }}
            >
              <img
                src={announcementIcon}
                alt="NewImage"
                style={{ position: "absolute", top: "0px", left: "0px" }}
              />
              <img
                src={img}
                style={{
                  width: "455px",
                  height: "230px",
                  borderRadius: "5%",
                  padding: "6px",
                }}
              />
            </div>
            <div style={{ marginTop: "248px", padding: "10px 20px" }}>
              <h3
                style={{ height: "40px", textTransform: "inherit" }}
                className="previewcolor_heading"
              >
                {title}
              </h3>
              <p
                style={{ wordWrap: "break-word", textTransform: "inherit" }}
                className="previewcolor_subheading"
              >
                {body}
              </p>
            </div>
          </>
        )}
      </div>
    </div>
    // </div>
    // <div className="thisIsMain" style={styles.thisIsMain}>
    // <div className="inAppNotification" style={styles.inAppNotification}>
    //   <img
    //     src={
    //       announcementIcon
    //     }
    //     alt="NewImage"
    //     style={{ position: "absolute", top: "-35px", left: "-35px" }}
    //   />
    //     <div className="notificationHeader" style={styles.notificationHeader}>
    //       {/* <h4
    //         className="mr-auto"
    //         style={{ marginBottom: "0px", marginLeft: "50px" }}
    //       >
    //         {title}
    //       </h4> */}
    //       <button
    //         type="button"
    //         className="ml-2 mb-1 close"
    //         onClick={() => {
    //           callTheSeenAPI(utils.getAid(), cid, campId);
    //           setIfDisplay(false);
    //         }}
    //       >
    //         <span aria-hidden="true">&times;</span>
    //       </button>
    //     </div>
    //   <Link style={{cursor: "pointer"}} title={"Click here!"} target="_blank" to={{pathname: keyObj }}>
    //     <div className="notificationBody" style={styles.notificationBody}>
    //       <img src={img} style={{ width: "100%" }} height={200} />
    //       <h4
    //         className="mr-auto"
    //         style={{ marginBottom: "0px", marginLeft: "50px" }}
    //       >
    //         {title}
    //       </h4>
    //       <hr style={{ width: "90%", marginLeft: "5%" }}></hr>
    //       <p style={{ fontSize: "18px", wordWrap: "break-word" }}>{body}</p>
    //     </div>
    //   </Link>
    // </div>
    // </div>
  );
};
