import { URLS } from "../constants/constants";

const initialState = {
  toggle_j_grp_modal: false,
  is_gcode_valid: null,
  err_code: "",
  form_incomplete: false,
  err_msg: "",
  g_pic_url: "",
  g_members: null,
  g_name: null,
  is_member: false,
  gd_url: null,
  groups: [],
  groupmembers: [],
  gd_quiz: [],
  gd_video: [],
  gd_course: [],
  selected_crs_id: null,
  selected_crs_is_adpt: false,
  gd_gname: "",
  gd_is_back: false,
  gsetting_is_back: false,
  gd_desc: "",
  gd_gpic: URLS.DEFAULT_GPIC_URL,
  edit_gpic: URLS.DEFAULT_GPIC_URL,
  to_mgp: false, //redirect to my group page
};

const joinGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case "TOGGLE_GRP_JOIN_MODAL": {
      return { ...state, toggle_j_grp_modal: action.payload.value };
    }
    case "IS_GCODE_VALID":
      if (!action.is_valid) {
        return {
          ...state,
          is_gcode_valid: action.is_valid,
          form_incomplete: action.form_incomplete,
          err_code: action.err_code,
          err_msg: action.err_msg,
        };
      } else {
        return {
          ...state,
          is_gcode_valid: action.is_valid,
          form_incomplete: action.form_incomplete,
          err_code: action.err_code,
          err_msg: action.err_msg,
          g_name: action.g_name,
          g_pic_url: action.g_pic_url,
          g_members: action.g_members,
          is_member: action.is_member,
          g_code: action.g_code,
          g_id: action.g_id,
          selected_crs_id: action.selected_crs_id,
          selected_crs_is_adpt: action.selected_crs_is_adpt,
        };
      }
    case "CANCEL_JOINING":
      return {
        ...state,
        is_gcode_valid: null,
        toggle_j_grp_modal: false,
        err_code: "",
        form_incomplete: false,
        err_msg: "",
        g_pic_url: "",
        g_members: null,
        g_name: null,
        is_member: false,
        gd_url: null,
      };
    case "SET_JOIN_GRP_URL":
      return {
        ...state,
        gd_url: action.gd_url,
        groups: action.groups,
      };
    case "UPDATE_USER_GROUPS":
      return {
        ...state,
        groups: action.groups,
      };
    case "USER_JOINED_GROUPS":
      return {
        ...state,
        groups: action.groups,
        groupmembers: action.groupmembers,
      };
    case "UPDATE_JOINED_GROUPS":
      return {
        ...state,
        groups: action.groups,
      };
    case "NULL_GRP_CRS":
      return {
        ...state,
        groups: action.groups,
        groupmembers: action.groupmembers,
      };
    case "SET_GROUP_DETAILS":
      return {
        ...state,
        // is_admin	   : action.is_admin,
        gd_quiz: action.quiz,
        gd_video: action.video,
        gd_course: action.course,
        gd_feed: action.feed,
      };
    case "SET_GROUP_DETAILS_FROM_SIDEBAR":
      // var groups = state.groups;
      // if(groups[action.index] && groups[action.index].gtyp){

      // }
      return {
        ...state,
        // is_admin	   : action.is_admin,
        // groups 			:
        gd_gtype: action.grp_type,
        g_index: action.index,
        gd_code: action.code,
        gd_id: action._id,
        gd_gname: action.name,
        gd_desc: action.gd_desc,
        gd_gpic: action.gpic ? action.gpic : URLS.DEFAULT_GPIC_URL,
      };
    case "NULLIFY_PLUGINS":
      return {
        ...state,
        gd_quiz: [],
        gd_video: [],
        gd_course: [],
        gd_feed: [],
      };
    case "CHANGE_GROUP_TYPE":
      return {
        ...state,
        groups: action.groups,
        gd_gtype: action.grp_type,
      };
    case "SET_MEMBER_TYPE":
      return {
        ...state,
        is_admin: action.is_admin,
      };
    case "SET_GD_BACK":
      return {
        ...state,
        gd_is_back: true,
      };
    case "RESET_GD_BACK":
      return {
        ...state,
        gd_is_back: false,
      };
    case "SET_GSETTING_BACK":
      return {
        ...state,
        gsetting_is_back: true,
      };
    case "RESET_GSETTING_BACK":
      return {
        ...state,
        gsetting_is_back: false,
      };
    case "REDIRECT_TO_GRP_DTL":
      return {
        ...state,
        to_mgp: action.val,
        groups: action.groups,
      };
    case "REDIRECT_TO_GRP_DTL_SWITCH_PROFILE":
      return {
        ...state,
        to_mgp: action.val,
      };
    case "REDIRECT_TO_MGP":
      return {
        ...state,
        to_mgp: action.val,
      };
    case "DISABLE_TO_GRP_DTL":
      return {
        ...state,
        to_mgp: action.val,
      };
    case "REMOVE_GPIC":
      return {
        ...state,
        edit_gpic: action.edit_gpic,
        groups: action.groups,
        gd_gpic: action.gd_gpic,
      };
    case "REMOVE_GROUP":
      return {
        ...state,
        groups: action.groups,
        to_mgp: true,
      };
    case "UPDATE_GPIC":
      return {
        ...state,
        edit_gpic: action.edit_gpic,
      };
    case "UPDATE_GROUP_NAME":
      return {
        ...state,
        gd_gname: action.gd_gname,
        groups: action.groups,
      };
    case "SET_GPIC_EXT":
      return {
        ...state,
        gpic_ext: action.gpic_ext,
      };
    case "UPDATE_CROPPED_GPIC":
      return {
        ...state,
        cropped_gpic: action.cropped_gpic,
      };
    case "UPDATE_GROUP_PIC":
      return {
        ...state,
        gd_gpic: action.gd_gpic,
        groups: action.groups,
      };
    case "DISABLE_GRP_DTL_REDIRECT":
      return {
        ...state,
        is_gcode_valid: null,
        toggle_j_grp_modal: false,
        err_code: "",
        form_incomplete: false,
        err_msg: "",
        g_pic_url: "",
        g_members: null,
        g_name: null,
        is_member: false,
        gd_url: null,
      };
    case "UPDATE_PLUGIN_VIDEO":
      return {
        ...state,
        gd_video: action.plugins,
      };
    case "UPDATE_PLUGIN_QUIZ":
      return {
        ...state,
        gd_quiz: action.plugins,
      };
    case "UPDATE_GROUP_DESC":
      return {
        ...state,
        groups: action.groups,
        gd_desc: action.desc,
      };
    default:
      return state;
  }
};

export default joinGroupReducer;
