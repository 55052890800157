import React, { Component } from "react";
import Footer from "../footers/Footer";
import ImageCrop from "../utils/ImageCrop";
// import Resize from '../utils/demo';
// import Cp from '../utils/croppie';
import { connect } from "react-redux";
import queryString from "query-string";
import { Redirect } from "react-router";
import {
  updateGPicURL,
  updateGroupDetailsToBackend,
  setGroupDetailsInSettings,
} from "../../actions/groupAction";
import EditGroupModal from "../modals/EditGroupModal";
import {
  POPOVER_CONTENT,
  POPOVER_HEADINGS,
  POPOVER_TYPE,
  POPOVER_HIDETIME,
  URLS,
  PIC_TYPE,
} from "../../constants/constants";
import { showPopover, setNotFound } from "../../actions/utilAction";
import { getGroupPlugins } from "../../actions/groupAction";
import LoadingModal from "../modals/LoadingModal";
import err_chat from "../../images/err-chat.svg";
import DeletePhotoModal from "../modals/DeletePhotoModal";
import DeletePGModal from "../modals/DeletePGModal";
import pencil from "../../images/pencil.svg";
import { PAGE_HEADINGS } from "../../constants/constants";
import { setPageHeading } from "../../actions/utilAction";
import Loader from "../utils/Loader";

class EditGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: "",
      img_src: null,
      current_ppic: URLS.DEFAULT_PPIC_URL,
      open_edit_modal: false,
      gd_gname: "",
      is_gname_null: false,
      is_dpm_open: false,
    };
  }
  componentDidUpdate(prev_props) {
    var query_params = queryString.parse(this.props.location.search);
    if (prev_props.gd_gpic != this.props.gd_gpic && this.props.gd_gpic) {
      this.props.updateGPicURL(this.props.gd_gpic);
    }
    if (prev_props.gd_gname != this.props.gd_gname && this.props.gd_gname) {
      this.setState({
        gd_gname: this.props.gd_gname,
      });
    }
    if (this.props.is_admin != prev_props.is_admin) {
      if (!this.props.is_admin) {
        this.props.setNotFound(true);
      }
    }
    if (
      this.props.groups != prev_props.groups &&
      this.props.groups &&
      this.props.groups.length > 0
    ) {
      this.props.setGroupDetailsInSettings(query_params.gid, this.props.groups);
    }
    if (this.props.gd_gname != prev_props.gd_gname) {
      this.props.setPageHeading(this.props.gd_gname);
    }
  }
  componentDidMount() {
    // alert(this.props.gsetting_is_back);
    this.props.setPageHeading(this.props.gd_gname);
    var query_params = queryString.parse(this.props.location.search);
    if (this.props.gd_gpic) {
      this.props.updateGPicURL(this.props.gd_gpic);
      this.setState({
        gd_gname: this.props.gd_gname,
      });
    }
    if (!query_params.gid) {
      this.props.setNotFound(true);
      return;
    }
    this.props.setGroupDetailsInSettings(query_params.gid, this.props.groups);
    this.props.getGroupPlugins(query_params.gid, query_params.pid, true);
  }
  toggleDeletePhotoModal = () => {
    this.setState({
      is_dpm_open: !this.state.is_dpm_open,
    });
  };
  toggleDeletePGModal = () => {
    this.setState({
      is_dpgm_open: !this.state.is_dpgm_open,
    });
  };
  displayPopover = (heading, msg, type) => {
    this.props.showPopover(heading, msg, type);
  };
  toggleEditModal = () => {
    this.setState({
      open_edit_modal: !this.state.open_edit_modal,
    });
  };

  updateGroupDetails = () => {
    var update_obj = {};
    var current_name = this.groupNameToSave();
    if (!current_name || current_name == "") {
      this.setState({
        is_gname_null: true,
      });
      return;
    }
    if (current_name != this.props.gd_gname) {
      update_obj.name = current_name;
    }
    if (this.props.edit_gpic != this.props.gd_gpic) {
      update_obj.gpic = this.props.edit_gpic;
      update_obj.gpic_ext = this.props.gpic_ext;
    }
    if (Object.keys(update_obj).length > 0) {
      this.props.updateGroupDetailsToBackend(
        update_obj,
        this.props.groups,
        this.props.gid
      );
    }
  };

  groupNameToSave = () => {
    var name = this.state.gd_gname.trim();
    var temp = name.split(" ");
    name = "";
    for (var i = 0; i < temp.length; i++) {
      if (temp[i].length > 0 && temp[i] != " ") {
        name = name + temp[i].trim() + " ";
      }
    }
    name = name.trim();
    return name;
  };

  convertDataURIToBlob = (dataURI) => {
    const type = dataURI.split(";")[0].split("/")[1];
    var binary = atob(dataURI.split(",")[1]);
    var array = [];
    for (var i = 0; i < binary.length; i++) {}
    array.push(binary.charCodeAt(i));
    return new Blob([new Uint8Array(array)], { type: "image/" + type });
  };

  setGroupName = (e) => {
    if (this.validateGroupName(e.target.value)) {
      if (e.target && e.target.value && e.target.value.length > 25) {
        this.displayPopover(
          POPOVER_HEADINGS.GRP_NAME_LENGTH_EXCEEDED,
          POPOVER_CONTENT.GRP_NAME_LENGTH_EXCEEDED,
          POPOVER_TYPE.ERROR
        );
        return;
      }
      this.setState({
        gd_gname: e.target.value,
        is_gname_null: false,
      });
    }
  };
  validateGroupName = (name) => {
    // var special_char_format = /[!@#$%^&*()+\-=\[\]{};':"\\|,<>\/?]/;
    // if(name && !isNaN(name)){
    //     this.displayPopover(POPOVER_HEADINGS.NAME_START_WITH_NUM,POPOVER_CONTENT.NAME_START_WITH_NUM,POPOVER_TYPE.ERROR);
    //     return false;
    // }
    // else if(name.includes(" .") || name.includes("..") || name == "."){
    //     this.displayPopover(POPOVER_HEADINGS.INVALID_NAME,POPOVER_CONTENT.INVALID_NAME,POPOVER_TYPE.ERROR);
    //     return false;
    // }
    // else if(name.length > 25){
    //     this.displayPopover(POPOVER_HEADINGS.P_NAME_LENGTH_EXCEEDED,POPOVER_CONTENT.P_NAME_LENGTH_EXCEEDED,POPOVER_TYPE.ERROR);
    //     return false;
    // }
    // else if(special_char_format.test(name)){
    //     // alert("special character")
    //     this.displayPopover(POPOVER_HEADINGS.SPECIAL_CHARACTER,POPOVER_CONTENT.SPECIAL_CHARACTER,POPOVER_TYPE.ERROR);
    //     return false;
    // }
    return true;
  };
  redirectToMyGroupPage = () => {
    return <Redirect to="/dashboard/group" />;
  };

  render() {
    if (this.props.to_mgp) {
      // this.props.disableMyGroupRedirection();
      return this.redirectToMyGroupPage();
    }
    if (!this.props.gsetting_is_back) {
      return (
        <div className="group-db-content-wrapper">
          <div className="container-fluid dashboard-content">
            {/* <div className="content-header-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <p className="content-header">Group Settings</p>
                            </div> */}
            <form>
              <div className=" profile-setting-container-row">
                <div className="profile-setting-container col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                  <div className="row">
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-1"></div>
                    <div className="ps-main-content col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                      <p className="edit-profile-heading">Edit Group</p>
                      <div className="img-container">
                        <img
                          onClick={this.toggleEditModal}
                          className="profile-img"
                          src={this.props.edit_gpic}
                        ></img>
                        <div className="edit-img-container">
                          <div
                            className="edit-img"
                            onClick={this.toggleEditModal}
                          >
                            <img src={pencil} />
                          </div>
                        </div>
                      </div>
                      <div className="pf-content-container">
                        <p className="pf-name">Group Name</p>
                        <div className="ps-inp-container">
                          <input
                            className={
                              !this.state.is_gname_null
                                ? "ps-inp"
                                : "ps-inp err-inp"
                            }
                            type="text"
                            value={this.state.gd_gname}
                            onChange={(e) => this.setGroupName(e)}
                          ></input>
                          <p
                            className={
                              "epf-err-msg " +
                              (this.state.is_gname_null ? "err-msg" : "hide")
                            }
                          >
                            <img src={err_chat} className="err-icon"></img>{" "}
                            Group Name can't be empty!
                          </p>
                        </div>
                        <input
                          className="ps-update-btn"
                          type="button"
                          onClick={this.updateGroupDetails}
                          value="Save Details"
                        ></input>
                        <input
                          className="dp-btn"
                          onClick={this.toggleDeletePGModal}
                          type="button"
                          value="Delete Group"
                        ></input>
                      </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-1"></div>
                  </div>
                </div>
              </div>
              <LoadingModal />
              <EditGroupModal
                open={this.state.open_edit_modal}
                toggleEditModal={this.toggleEditModal}
                toggleDeletePhotoModal={this.toggleDeletePhotoModal}
                is_dpm_open={this.state.is_dpm_open}
                is_dpgm_open={this.state.is_dpgm_open}
                current_gpic={this.state.current_gpic}
              />

              <DeletePhotoModal
                is_dpm_open={this.state.is_dpm_open}
                toggleEditModal={this.toggleEditModal}
                pic_type={PIC_TYPE.GROUP_PIC}
                toggleDeletePhotoModal={this.toggleDeletePhotoModal}
              />

              <DeletePGModal
                is_dpgm_open={this.state.is_dpgm_open}
                toggleDeletePGModal={this.toggleDeletePGModal}
                pic_type={PIC_TYPE.GROUP_PIC}
              />
            </form>
          </div>
          <Footer />
        </div>
      );
    } else {
      return <Loader />;
    }
  }
}

const mapStateToProps = (state) => ({
  show_menu: state.ac_dd.show_menu,
  groups: state.j_grp.groups,
  profiles: state.profile.profiles,
  p_name: state.profile.p_name,
  ppic: state.profile.ppic,
  edit_gpic: state.j_grp.edit_gpic,
  profile_map: state.profile.profile_map,
  gpic_ext: state.j_grp.gpic_ext,
  groups: state.j_grp.groups,
  gd_gname: state.j_grp.gd_gname,
  gd_gtype: state.j_grp.gd_gtype,
  gd_gpic: state.j_grp.gd_gpic,
  gd_feed: state.j_grp.gd_feed,
  gd_quiz: state.j_grp.gd_quiz,
  gd_video: state.j_grp.gd_video,
  gd_code: state.j_grp.gd_code,
  gd_course: state.j_grp.gd_course,
  is_admin: state.j_grp.is_admin,
  to_mgp: state.j_grp.to_mgp,
  gid: state.j_grp.gd_id,
  gsetting_is_back: state.j_grp.gsetting_is_back,
});

const mapDispatchToProps = {
  updateGPicURL,
  updateGroupDetailsToBackend,
  showPopover,
  getGroupPlugins,
  setNotFound,
  setPageHeading,
  setGroupDetailsInSettings,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditGroup);
