import { connect } from "react-redux";
import React from "react";
import { withRouter } from "react-router";
import { Modal } from "reactstrap";
import Spinner from "../utils/Spinner";
import {
  toggleJoinGroupModal,
  cancelJoining,
} from "../../actions/joinGroupAction.js";
import close_img from "../../images/close-btn.svg";
import ImageCrop from "../utils/ImageCrop";
import { KEY_CODES } from "../../constants/constants";
import { showPopover } from "../../actions/utilAction";
import { updateGPicURL } from "../../actions/groupAction";
import GroupPicCropper from "../utils/GroupPicCropper";
import { URLS } from "../../constants/constants";

class EditGroupModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // img_src:
    };
  }

  componentDidUpdate() {}
  componentDidMount() {
    document.addEventListener("keydown", this.keydownHandler);
    this.setState({
      current_gpic: this.props.current_gpic,
    });
  }

  cancel = () => {
    this.props.cancelJoining();
  };
  closeModal = () => {};

  isImageCropped = () => {
    var crop = this.state.crop;
    if (!crop || crop.width == 0 || crop.height == 0) {
      return false;
    } else {
      return true;
    }
  };

  choosePic = () => {
    document.getElementById("edit-file").click();
  };
  keydownHandler = (e) => {
    if (
      e.keyCode == KEY_CODES.ESC_KEY &&
      !this.props.is_dpm_open &&
      !this.props.is_dpgm_open &&
      this.props.open
    ) {
      this.props.updateGPicURL(this.state.orignal_pic);
      this.props.toggleEditModal();
    }
  };
  setOriginalPicAndCrop = (orignal_pic, crop) => {
    this.setState({
      orignal_pic: orignal_pic,
      crop: crop,
    });
  };
  toggleEditModal = () => {
    // if(!this.isImageCropped()){
    this.props.updateGPicURL(this.state.orignal_pic);
    //    }
    this.props.toggleEditModal();
  };
  saveGroupPic = () => {
    //the profile pic
    this.props.updateGPicURL(this.props.cropped_gpic);
    this.props.toggleEditModal();
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.open}
          className={"edit-modal-container"}
          centered={true}
        >
          <div className="edit-modal">
            <p className="modal-header">
              Edit Photo{" "}
              <img
                className="close-btn"
                onClick={this.toggleEditModal}
                src={close_img}
              ></img>
            </p>
            <div className="img-container">
              <div
                className={
                  this.props.edit_gpic == this.props.gpic
                    ? "edit-modal-cover hide"
                    : "edit-modal-cover hide"
                }
              ></div>
              {/* <ImageCrop img_src={this.props.current_ppic} setOriginalPicAndCrop={this.setOriginalPicAndCrop}/> */}
            </div>
            <GroupPicCropper
              setOriginalPicAndCrop={this.setOriginalPicAndCrop}
            />
            {/* <p className="valid-msg">Image should be up to 800x600px & size up to 5mb</p> */}
            <p className="valid-msg">
              Update your profile pic for more personalized experience.
            </p>
            <div className="footer">
              <button
                onClick={this.props.toggleDeletePhotoModal}
                className={
                  this.props.gd_gpic != URLS.DEFAULT_GPIC_URL
                    ? "del-btn"
                    : "hide"
                }
                id="edit-modal-del-btn"
              >
                Delete Photo
              </button>
              {/* disabled={(!this.state.crop || (!this.state.crop.height || !this.state.crop.width)) ? true : false} */}
              <button
                onClick={this.saveGroupPic}
                className="save-btn"
                disabled={
                  this.props.gd_gpic == this.props.edit_gpic ? true : false
                }
              >
                Crop Photo
              </button>
              <button onClick={this.choosePic} className="change-btn">
                Change Photo
              </button>
            </div>
          </div>
          {/* <LeaveGroupModal is_open={true}/> */}
          {/* <ImageCrop img_src={this.props.current_ppic}/> */}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  cropped_gpic: state.j_grp.cropped_gpic,
  edit_gpic: state.j_grp.edit_gpic,
  gd_gpic: state.j_grp.gd_gpic,
  is_admin: state.j_grp.is_admin,
});

const mapDispatchToProps = {
  toggleJoinGroupModal,
  cancelJoining,
  showPopover,
  updateGPicURL,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditGroupModal)
);
