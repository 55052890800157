// import React from 'react';
// import Switch from '@material-ui/core/Switch';

// export default function Switches() {
//   const [state, setState] = React.useState({
//     checkedA: true,
//     checkedB: true,
//   });

//   const handleChange = name => event => {
//     setState({ ...state, [name]: event.target.checked });
//   };

//   return (
//     <div>
//       {/* <Switch
//         checked={state.checkedA}
//         onChange={handleChange('checkedA')}
//         value="checkedA"
//         inputProps={{ 'aria-label': 'secondary checkbox' }}
//       /> */}
//       <Switch
//         checked={state.checkedB}
//         onChange={handleChange('checkedB')}
//         value="checkedB"
//         color="primary"
//         inputProps={{ 'aria-label': 'primary checkbox' }}
//       />
//       {/* <Switch value="checkedC" inputProps={{ 'aria-label': 'primary checkbox' }} />
//       <Switch disabled value="checkedD" inputProps={{ 'aria-label': 'disabled checkbox' }} />
//       <Switch disabled checked value="checkedE" inputProps={{ 'aria-label': 'primary checkbox' }} />
//       <Switch
//         defaultChecked
//         value="checkedF"
//         color="default"
//         inputProps={{ 'aria-label': 'checkbox with default color' }}
//       /> */}
//     </div>
//   );
// }

import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { purple } from "@material-ui/core/colors";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { changeGroupType } from "../../actions/groupAction";
import queryString from "query-string";
import GroupChangeModal from "../modals/GroupChangeModal";
import { setSlidingStrip, resetSlidingStrip } from "../../actions/utilAction";

const MODAL_MSG = {
  admin_msg: "Now, Admins can post only in this group",
  all_mem_msg: "Now, All members can post in this group",
};

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 30,
    height: 18,
    padding: 0,
    // paddingRight: ,
    // paddingRight: -1,
    marginLeft: 10,
    // paddingTop: 12,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#31a128",
        borderColor: "#31a128",
        // border: `10px solid ${theme.palette.grey[500]}`
      },
    },
  },
  thumb: {
    width: 14,
    height: 14,
    // marginTop: 1,
    // marginRight: 1,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

function CustomizedSwitches(props) {
  const [state, setState] = React.useState({
    checkedC: props.admin_only,
    is_open: false,
  });

  const handleChange = (name) => (event) => {
    var query_params = queryString.parse(props.location.search);
    var gid = query_params.gid;
    var msg;
    props.changeGroupType(gid, props.grp_type, props.groups);
    if (props.grp_type) {
      msg = MODAL_MSG.all_mem_msg;
    } else {
      msg = MODAL_MSG.admin_msg;
    }
    props.setSlidingStrip(msg);
    setState({
      ...state,
      is_open: true,
      [name]: event.target.checked,
      msg: msg,
    });
    // setState({ ...state,  });
  };
  const closeModal = () => {
    setState({ ...state, is_open: false });
  };

  return (
    <Fragment>
      <AntSwitch
        checked={props.grp_type}
        onChange={handleChange("checkedC")}
        value="checkedC"
      />
      {/* <GroupChangeModal 
    // open={}
    is_open = {state.is_open}
    closeModal = {closeModal}
    msg={state.msg}
    /> */}
    </Fragment>
  );
}

const mapStateToProps = (state) => ({
  is_admin: state.j_grp.is_admin,
  groups: state.j_grp.groups,
});

const mapDispatchToProps = {
  changeGroupType,
  setSlidingStrip,
  resetSlidingStrip,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomizedSwitches)
);
