import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 32,
    height: 20,
    padding: 0,
    // paddingRight: ,
    // paddingRight: -1,
    marginLeft: 10,
    // paddingTop: 12,
    display: "flex",
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    "&$checked": {
      transform: "translateX(12px)",
      color: theme.palette.common.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: "#31a128",
        borderColor: "#31a128",
        // border: `10px solid ${theme.palette.grey[500]}`
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    // marginTop: 1,
    // marginRight: 1,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 20 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

export default function AdminCreateGroupSwitch() {
  const [state, setState] = React.useState({
    checkedC: true,
  });

  const handleChange = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };

  return (
    <AntSwitch
      checked={state.checkedC}
      onChange={handleChange("checkedC")}
      value="checkedC"
    />
  );
}
