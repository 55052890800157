import { connect } from "react-redux";
import React from "react";
import { withRouter } from "react-router";
import { Modal } from "reactstrap";
import Spinner from "../utils/Spinner";
import {
  toggleJoinGroupModal,
  cancelJoining,
} from "../../actions/joinGroupAction.js";

class GroupChangeModal extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate() {}

  cancel = () => {
    this.props.cancelJoining();
  };

  closeModal = () => {};

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.is_open}
          className={this.props.className}
          centered={true}
        >
          <div className="cg-modal">
            <p className="heading">Success!</p>
            <p className="msg">{this.props.msg}</p>
            <div className="footer">
              <button onClick={this.props.closeModal} className="ok-btn">
                OK
              </button>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  g_name: state.j_grp.g_name,
  g_members: state.j_grp.g_members,
  g_pic_url: state.j_grp.g_pic_url,
  toggle_j_grp_modal: state.j_grp.toggle_j_grp_modal,
  is_member: state.j_grp.is_member,
  g_id: state.j_grp.g_id,
  g_code: state.j_grp.g_code,
});

const mapDispatchToProps = {
  toggleJoinGroupModal,
  cancelJoining,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(GroupChangeModal)
);
