const initialState = {
  tr_crs: null,
  tr_crs_to_render: null,
  fr_crs: null,
  fr_crs_to_render: null,
  tr_grp: null,
  tr_grp_to_render: null,
  ft_grp: null,
  ft_grp_to_render: null,
  joined_gids: {},
  all_ex_gids: [],
};

const exploreReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TRENDING_COURSES":
      return {
        ...state,
        tr_crs: action.data,
      };
    case "SET_TRENDING_COURSES_TO_RENDER":
      return {
        ...state,
        tr_crs_to_render: action.data,
      };
    case "SET_RUNNING_COURSES_TO_RENDER":
      return {
        ...state,
        rn_crs_to_render: action.data,
      };
    case "SET_FINANCE_COURSES_TO_RENDER":
      return {
        ...state,
        fn_crs_to_render: action.data,
      };
    case "SET_TECHNOLOGY_COURSES_TO_RENDER":
      return {
        ...state,
        tn_crs_to_render: action.data,
      };
    case "SET_ANALYTICS_COURSES_TO_RENDER":
      return {
        ...state,
        an_crs_to_render: action.data,
      };
    case "SET_MARKETING_COURSES_TO_RENDER":
      return {
        ...state,
        mn_crs_to_render: action.data,
      };
    case "SET_RUNNING_COURSES":
      return {
        ...state,
        rn_crs: action.data,
      };
    case "SET_FINANCE_COURSES":
      return {
        ...state,
        fn_crs: action.data,
      };
    case "SET_TECHNOLOGY_COURSES":
      return {
        ...state,
        tn_crs: action.data,
      };
    case "SET_ANALYTICS_COURSES":
      return {
        ...state,
        an_crs: action.data,
      };
    case "SET_MARKETING_COURSES":
      return {
        ...state,
        mn_crs: action.data,
      };
    case "SET_FREE_COURSES":
      return {
        ...state,
        fr_crs: action.data,
      };
    case "SET_FREE_COURSES_TO_RENDER":
      return {
        ...state,
        fr_crs_to_render: action.data,
      };
    case "SET_TRENDING_GROUPS":
      return {
        ...state,
        tr_grp: action.data,
      };
    case "SET_TRENDING_GROUPS_TO_RENDER":
      return {
        ...state,
        tr_grp_to_render: action.data,
      };
    case "SET_FEATURED_GROUPS":
      return {
        ...state,
        ft_grp: action.data,
      };
    case "SET_FEATURED_GROUPS_TO_RENDER":
      return {
        ...state,
        ft_grp_to_render: action.data,
      };
    case "SET_EX_G_INFO":
      return {
        ...state,
        joined_gids: action.joined_gids,
        all_ex_gids: action.all_ex_gids,
      };
    default:
      return state;
  }
};

export default exploreReducer;
